import { clamp } from '@maaax/utils/Math/clamp'
import Emitter from './emitter'
import { inverseLerp } from './inverseLerp'
import { onScroll as _onScroll } from './scroll'

const getOffset = (offset) => {
  const amount = {
    top: 0,
    center: 0.5,
    bottom: 1,
  }[offset]

  return window.innerHeight * amount
}

/*
 *  Returns scroll progress of an element from 0-1
 */
export const scrollProgress = ({
  ref,
  onScroll,
  start = 'bottom',
  end = 'top',
} = {}) => {
  const cache = {
    start: 100,
    end: 200,
    lastProgress: 0,
  }

  const onResize = () => {
    const bounds = ref.getBoundingClientRect()
    const scrollStart = window.scrollY + bounds.top

    cache.start = clamp(scrollStart - getOffset(start), 0, Infinity)
    cache.end = scrollStart + bounds.height - getOffset(end)
  }

  const calcScrollProgress = (y) => {
    if (!cache) return
    const { start, end, lastProgress } = cache
    const progress = inverseLerp(start, end, y)

    // Comparing to last progress ensures 0 and 1 progress are always fired
    // to properly put the animation on the first or last frame
    if (lastProgress <= 1 && lastProgress > 0 && onScroll) {
      const clampedProgress = clamp(progress, 0, 1)

      if (onScroll) onScroll(clampedProgress)
    }

    cache.lastProgress = progress
  }

  onResize()
  Emitter.on('resize', onResize)
  return _onScroll(calcScrollProgress)
}
