import {
  disableScroll,
  enableScroll,
} from '../../utils/disableScroll'
import Emitter from '../../utils/emitter'
import { removeNewLine } from '../../utils/string'

export let isProjectOpen = false
let lastScrollPosition = 0

const ProjectNav = (projectsWrapper) => {
  if (!projectsWrapper) return

  const openProject = (project) => {
    if (!project) return
    isProjectOpen = true

    // Stop listening to scroll event so view stays locked in place
    disableScroll()

    projectsWrapper.style.cssText = `pointer-events: none; transform: translateX(-10%)`

    // Extract project data
    const { video, videoMobile } = project.dataset
    const projectName = project.querySelector('.project-name')
    const client = project.querySelector('.client')
    const image = project.querySelector('img')

    lastScrollPosition = window.scrollY

    Emitter.emit('project-open', {
      videoDesktop: video,
      videoMobile,
      client: client && removeNewLine(client.innerText),
      name: projectName && removeNewLine(projectName.innerText),
      image: image && image.currentSrc,
    })
  }

  const closeProject = () => {
    if (!isProjectOpen) return
    enableScroll()
    window.scrollTo(0, lastScrollPosition)

    isProjectOpen = false

    projectsWrapper.style.transform = ''
    projectsWrapper.style.pointerEvents = ''

    setTimeout(() => {
      Emitter.emit('project-close')
    }, 0)
  }

  const onKeyUp = (e) => {
    if (e.key === 'Escape') closeProject()
  }

  // Add Events
  document.addEventListener('keyup', onKeyUp)
  document.querySelectorAll('button.project').forEach((element) => {
    element.addEventListener('click', () => {
      openProject(element)
    })
  })
  document
    .querySelectorAll('#video-player .close button')
    .forEach((element) => {
      element.addEventListener('click', closeProject)
    })

  return {
    destroy: () => {
      document.removeEventListener('keyup', onKeyUp)
    },
  }
}

export default ProjectNav
