import Highway from '@dogstudio/highway'
import { scrollToAbout } from '..'
import { disableScroll, enableScroll } from '../utils/disableScroll'

class DefaultTransition extends Highway.Transition {
  in({ from, to, trigger, done }) {
    from.remove()
    enableScroll()
    window.scrollTo(0, 0)

    // Scroll to about
    if (trigger?.classList?.contains('about-link'))
      scrollToAbout('instant')

    done()
  }

  out({ from, trigger, done }) {
    disableScroll()

    setTimeout(() => {
      done()
    }, 350)
  }
}

export default DefaultTransition
