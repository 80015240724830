import Highway from '@dogstudio/highway'
import AboutImage from '../components/AboutImage'
import { ProjectGrid } from '../components/ProjectGrid'
import ProjectNav from '../components/ProjectNav'
import VideoPlayer from '../components/VideoPlayer'

let projectNav
let videoPlayer
let aboutImage
let projectGrid

class Home extends Highway.Renderer {
  onEnter() {
    window.scrollTo(0, 0)
    document.body.dataset.theme = 'yellow'

    setTimeout(() => {
      // Init components
      videoPlayer = VideoPlayer(
        document.querySelector('#video-player')
      )
      projectNav = ProjectNav(document.querySelector('.project-grid'))
      aboutImage = AboutImage()
      projectGrid = ProjectGrid(
        document.querySelector('.project-grid')
      )
    }, 100)
  }

  onLeave() {
    if (videoPlayer) videoPlayer.destroy()
    if (projectNav) projectNav.destroy()
    if (aboutImage) aboutImage.destroy()
    if (projectGrid) projectGrid.destroy()
    videoPlayer = null
    projectNav = null
    document.body.style.cssText = ''
  }
}

export default Home
