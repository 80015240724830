import { debounce } from '@maaax/utils/Functions/debounce'
import { isMobile } from '../../index'
import Emitter from '../../utils/emitter'
import {
  closeFullscreen,
  openFullscreen,
} from '../../utils/fullscreen'

// Format time to 00:24
const toMMSS = (time) => {
  const prependZero = (value) => (value < 10 ? `0${value}` : value)

  const minutes = Math.floor(time / 60)
  const seconds = Math.round(time - minutes * 60)

  return `${prependZero(
    minutes
  )}<span class="delimiter">:</span>${prependZero(seconds)}`
}

const VideoPlayer = (wrapper) => {
  if (!wrapper) return
  const fullscreenButton = wrapper.querySelector('.fullscreen button')
  const playButton = wrapper.querySelector('.play button')
  const progressSlider = wrapper.querySelector('.slider input')
  const time = wrapper.querySelector('.time p')
  const title = wrapper.querySelector('.title h2')

  let lastVideoState
  let video = null
  let isVisible = false
  let isVideoReady = false
  let isTransitionFinished = false
  let isFullscreen = false

  /**
   * Functions
   */
  const show = (data = {}) => {
    const { videoDesktop, videoMobile, client, name, image } = data
    if (isVisible) return
    isVisible = true
    isTransitionFinished = false

    // Create Video
    video = createVideo(isMobile ? videoMobile : videoDesktop, image)

    // Reset ui
    wrapper.classList.remove('is-hidden')
    time.innerHTML = toMMSS(0)
    progressSlider.value = 0
    title.innerText = !client
      ? name
      : isMobile
      ? `${client} \n ${name}`
      : `${client} — ${name}`

    debouncedHideUi()

    setTimeout(() => {
      if (!isVisible) return
      // FadeIn ui
      wrapper.classList.add('is-visible')

      isTransitionFinished = true
      onAllReady()
    }, 25)
  }

  const hide = () => {
    if (!isVisible) return
    isVisible = false

    if (isFullscreen) closeFullscreen()
    showUi()

    video.pause()
    // video.classList.remove('is-visible')
    video = null

    document.querySelector('.project-grid').style.visibility =
      'visible'
    wrapper.classList.remove('is-visible')

    setTimeout(() => {
      wrapper.removeChild(wrapper.querySelector('video'))
    }, 1000)
  }

  const hideUi = () => {
    if (isVisible && !isMobile) wrapper.classList.add('is-ui-hidden')
  }
  const debouncedHideUi = debounce(hideUi, 2000)

  const showUi = () => {
    wrapper.classList.remove('is-ui-hidden')
  }

  const toggleFullscreen = () => {
    if (isFullscreen) {
      isFullscreen = false
      closeFullscreen()
    } else {
      isFullscreen = true
      openFullscreen(wrapper)
    }
  }

  const togglePlay = () => {
    if (video.paused) {
      video.play()
    } else {
      video.pause()
    }
  }

  const onTimeUpdate = () => {
    if (!video) return
    const { currentTime, duration } = video

    // Calc progress between 0 - 1
    const progress = currentTime / duration
    progressSlider.value = progress

    // Update text
    time.innerHTML = toMMSS(currentTime)
  }

  const createVideo = (src, image) => {
    if (!src) return

    isVideoReady = false

    const video = document.createElement('video')
    const source = document.createElement('source')
    if (image) video.poster = image
    video.autoPlay = false
    video.preload = 'auto'

    source.src = src
    source.type = 'video/mp4'
    video.appendChild(source)

    if (isMobile) {
      video.addEventListener('pause', () => {
        // Fix controls visible on close on iOS
        video.controls = false
      })
      video.addEventListener('click', video.play)
    } else {
      video.addEventListener('click', togglePlay)
      video.addEventListener('timeupdate', onTimeUpdate)
    }
    video.addEventListener('canplay', () => {
      isVideoReady = true
      onAllReady()
    })

    wrapper.appendChild(video)

    return video
  }

  const onAllReady = () => {
    if (!isTransitionFinished || !isVideoReady) return
    debouncedHideUi()
    if (video) {
      video.classList.add('is-visible')

      if (!isMobile) video.play()
    }

    setTimeout(() => {
      debouncedHideUi()

      document.querySelector('.project-grid').style.visibility =
        'hidden'
    }, 1000)
  }

  const onMouseMove = () => {
    if (!isVisible) return
    showUi()
    debouncedHideUi()
  }

  /**
   * Add Events
   */
  window.addEventListener('mousemove', onMouseMove)

  progressSlider.addEventListener('input', (e) => {
    if (!video) return
    video.currentTime = e.target.value * video.duration
  })
  progressSlider.addEventListener('mousedown', () => {
    lastVideoState = video.paused ? 'paused' : 'playing'
    video.pause()
  })
  progressSlider.addEventListener('mouseup', () => {
    if (lastVideoState === 'playing') video.play()
  })

  playButton.addEventListener('click', togglePlay)
  fullscreenButton.addEventListener('click', toggleFullscreen)

  Emitter.on('project-open', show)
  Emitter.on('project-close', hide)

  return {
    destroy: () => {
      window.removeEventListener('mousemove', onMouseMove)
      Emitter.off('project-open', show)
      Emitter.off('project-close', hide)
    },
  }
}

export default VideoPlayer
