import Highway from '@dogstudio/highway'
import { debounce } from '@maaax/utils/Functions/debounce'
import Home from './pages/home'
import Impressum from './pages/impressum'
import DefaultTransition from './transitions/DefaultTransition'
import Emitter from './utils/emitter'

export let isMobile

const onResize = () => {
  const { innerWidth, innerHeight } = window

  isMobile = innerWidth < 768 || innerWidth / innerHeight < 1

  Emitter.emit('resize', {
    width: innerWidth,
    height: innerHeight,
  })
}

export const scrollToAbout = (behavior = 'smooth') => {
  document.querySelector('.home-about')?.scrollIntoView({ behavior })
}

// Events
const init = () => {
  window.addEventListener('resize', debounce(onResize, 250))
  onResize()

  new Highway.Core({
    renderers: {
      home: Home,
      impressum: Impressum,
    },
    transitions: {
      default: DefaultTransition,
    },
  })

  document
    .querySelector('nav .about-link')
    .addEventListener('click', () => scrollToAbout('smooth'))

  setTimeout(() => {
    document.body.classList.remove('is-loading')
    document.body.classList.add('is-loaded')
  }, 500)
}

document.fonts.ready.then(init)
