import { lerp, map } from '@maaax/utils'
import Emitter from '../../utils/emitter'

const AboutImage = (
  image = document.querySelector('.about-image'),
  hoverEl = document.querySelector('.about-text')
) => {
  let rafId
  let isRunning = false
  let maxWidth = window.innerWidth

  if (maxWidth < 768) return

  const mouse = {
    current: { x: 0, y: 0 },
    target: { x: 0, y: 0 },
  }

  const onResize = ({ width }) => {
    maxWidth = width

    if (width < 767) {
      cancelRaf()
      image.style.cssText = ''
    } else if (!isRunning) {
      tick()
    }
  }

  Emitter.on('resize', onResize)

  const onMouseMove = (e) => {
    mouse.target.x = map(e.x, 0, maxWidth, -10, 10)
    mouse.target.y = e.layerY * 0.5
  }

  hoverEl.addEventListener('mousemove', onMouseMove)

  const tick = () => {
    rafId = requestAnimationFrame(tick)
    isRunning = true

    mouse.current.x = lerp(mouse.current.x, mouse.target.x, 0.05)
    mouse.current.y = lerp(mouse.current.y, mouse.target.y, 0.05)

    image.style.cssText = `transform: translate(${mouse.current.x}vw, ${mouse.current.y}px)`
  }

  const cancelRaf = () => {
    if (rafId) {
      cancelAnimationFrame(rafId)
      isRunning = false
    }
  }

  tick()

  return {
    destroy: () => {
      cancelRaf()

      Emitter.off('resize', onResize)
      hoverEl.removeEventListener('mousemove', onMouseMove)
    },
  }
}

export default AboutImage
