import Highway from "@dogstudio/highway";

class Impressum extends Highway.Renderer {
  onEnter() {
		document.body.dataset.theme = "black";
	}

  onLeave() {}
}

export default Impressum;
