import { isMobile } from '../../index'
import { onScroll } from '../../utils/scroll'
import { scrollProgress } from '../../utils/scrollProgress'
import { isProjectOpen } from '../ProjectNav'

function easeInQuart(x) {
  return x * x * x * x
}

const updateProject = (el) => (progress) => {
  const p = easeInQuart(progress)

  // if (progress > 0 && p < 0.85) {
  //   el.classList.remove('hidden')
  // } else {
  //   el.classList.add('hidden')
  // }

  el.style.transform = `scale(${1 - p * 0.5}) translate3d(0px, ${
    p * 100
  }vh, 0px)`
}

export const ProjectGrid = (projectGrid) => {
  const homeProjects = document.querySelector('.home-projects')
  const projects = projectGrid.querySelectorAll('.project')
  let isHomeProjectsActive = false

  const killHomeProjectsScrollProgress = scrollProgress({
    ref: homeProjects,
    end: isMobile ? 'center' : 'bottom',
    onScroll: (p) => {
      isHomeProjectsActive = p > 0 && p < 1
    },
  })

  const killProjectsScroll =
    !isMobile &&
    [...projects].map((ref) =>
      scrollProgress({
        ref,
        start: 'center',
        onScroll: updateProject(ref),
      })
    )

  const update = (y) => {
    if (!isHomeProjectsActive && !isProjectOpen) {
      document.body.style.backgroundColor = ''
      homeProjects.style.setProperty('--text-color', 'var(--black)')
      return
    }

    const isBlack = isHomeProjectsActive || y < 100

    if (isBlack || isProjectOpen) {
      document.body.style.transition = 'background-color 0.4s linear'
      document.body.style.backgroundColor = 'var(--black)'

      if (isMobile) {
        homeProjects.style.setProperty('--text-color', 'var(--white)')
      }
    } else if (!isProjectOpen) {
      document.body.style.backgroundColor = ''
      homeProjects.style.setProperty('--text-color', 'var(--black)')
    }
  }

  const killScroll = onScroll(update)

  return {
    destroy: () => {
      if (killProjectsScroll.length)
        killProjectsScroll.forEach((cb) => cb())
      killScroll()
      killHomeProjectsScrollProgress()
    },
  }
}
